
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Hamburger from '@/components/Hamburger.vue'
import { ArrowDown } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'NavbarComponent',
  components: {
    Breadcrumb,
    Hamburger,
    ArrowDown
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'name'
    ])
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout () {
      localStorage.setItem('lastLoginUser', this.name) // 记录上一个登录的用户的用户名
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
})

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99304eb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_navbar = _resolveComponent("navbar")!
  const _component_app_main = _resolveComponent("app-main")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.classObj, "app-wrapper"])
  }, [
    (_ctx.device==='mobile'&&_ctx.sidebar.opened)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "drawer-bg",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutside && _ctx.handleClickOutside(...args)))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_sidebar, { class: "sidebar-container" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass({'fixed-header':_ctx.fixedHeader})
      }, [
        _createVNode(_component_navbar)
      ], 2),
      _createVNode(_component_app_main)
    ])
  ], 2))
}
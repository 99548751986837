import { createRouter, createWebHashHistory } from 'vue-router'

/* Layout */
import Layout from '@/layout'

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  }, {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  }, {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: { title: '首页', icon: 'dashboard' }
    },
    {
      path: 'user-profile',
      name: '个人中心',
      component: () => import('@/views/system/UserManage/UserProfile'),
      hidden: true,
      meta: { title: '个人中心' }
    }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '/*', redirect: '/404', hidden: true }
]

export const asyncRoutes = [
  {
    path: '/quant',
    component: Layout,
    redirect: '/quant/index',
    meta: {
      title: '币价管理',
      icon: 'el-icon-help'
    },
    children: [{
      path: 'exchange',
      name: '交易所管理',
      component: () => import('@/views/quant/exchange/index'),
      meta: { title: '交易所管理' }
    }, {
      path: 'exchange-stats',
      component: () => import('@/views/quant/exchange/Stats'),
      name: 'ExchangeStats',
      meta: { title: '交易所数据统计' },
      props: (route) => ({ exchangeID: parseInt(route.query.exchangeID) }),
      hidden: true
    }, {
      path: 'staking-stats',
      component: () => import('@/views/quant/staking/index'),
      name: 'StakingStats',
      meta: { title: 'Staking 利息统计' }
    }]
  },

  {
    path: '/explorer',
    component: Layout,
    redirect: '/nested/menu1',
    name: 'Explorer',
    meta: {
      title: '区块链浏览器',
      icon: 'el-icon-help'
    },
    children: [
      {
        path: 'addrs',
        component: () => import('@/views/explorer/addrs/index'),
        name: '持币地址列表',
        meta: {
          title: '持币地址列表'
        }
      },
      {
        path: 'addr-mark',
        component: () => import('@/views/explorer/mark/index'),
        name: 'Address Mark',
        meta: { title: '地址标签管理' }
      },
      {
        path: 'addr-track',
        component: () => import('@/views/explorer/track/index'),
        name: 'Address Track',
        meta: { title: '地址追踪' }
      }
    ]
  },

  {
    path: '/products',
    name: 'Products',
    component: Layout,
    redirect: '/products/repos',
    meta: {
      title: '产品管理',
      icon: 'el-icon-coin'
    },
    children: [
      {
        path: 'unsolved-issues',
        component: () => import('@/views/products-manager/VersionIssues.vue'),
        name: 'UnslovedIssues',
        props: (route) => ({}),
        meta: { title: 'Issues' }
      }, {
        path: 'repos',
        component: () => import('@/views/products-manager/ReposManager.vue'),
        name: 'Repos',
        meta: { title: 'Repository' }
      }, {
        path: 'projects',
        component: () => import('@/views/products-manager/ProjectsManager.vue'),
        props: (route) => ({ id: parseInt(route.query.id), name: route.query.name }),
        name: 'Projects',
        meta: { title: 'Projects' }
      }, {
        path: 'project-versions',
        component: () => import('@/views/products-manager/ProjectVersionsManager.vue'),
        name: 'ProjectVersions',
        hidden: true,
        props: (route) => ({ projectId: parseInt(route.query.projectId), projectName: route.query.projectName, version: route.query.version }),
        meta: { title: '项目版本管理' }
      }, {
        path: 'version-issues',
        component: () => import('@/views/products-manager/VersionIssues.vue'),
        name: 'VersionIssues',
        hidden: true,
        props: (route) => ({ versionId: parseInt(route.query.versionId), version: route.query.version }),
        meta: { title: '版本需求管理' }
      }, {
        path: 'products',
        component: () => import('@/views/products-manager/ProductsManager.vue'),
        name: 'Products',
        meta: { title: 'Products' }
      }, {
        path: 'product-projects',
        component: () => import('@/views/products-manager/ProductProjectsManager.vue'),
        name: 'ProductProjects',
        hidden: true,
        props: (route) => ({ productId: parseInt(route.query.productId) }),
        meta: { title: '产品关联项目管理' }
      }
    ]
  },

  {
    path: '/dapps',
    component: Layout,
    redirect: '/dapps/list',
    name: 'DApps',
    meta: {
      title: 'DApps 管理',
      icon: 'el-icon-coin'
    },
    children: [
      // 内容管理
      {
        path: 'list',
        component: () => import('@/views/dapps/index'),
        name: 'DAppsList',
        meta: { title: 'DApps 列表' }
      },
      {
        path: 'edit-usage',
        component: () => import('@/views/dapps/EditUsage'),
        name: 'DAppEditUsage',
        meta: { title: 'DApp 使用说明' },
        props: (route) => ({ id: parseInt(route.query.id) }),
        hidden: true
      },
      {
        path: 'types',
        component: () => import('@/views/dapps/types'),
        name: 'DAppsTypes',
        meta: { title: 'DApps 类型管理' }
      },
      {
        path: 'terms',
        component: () => import('@/views/dapps/terms'),
        name: 'DAppsTerms',
        meta: { title: 'DApps 协议管理' }
      },
      {
        path: 'terms-new',
        component: () => import('@/views/dapps/terms/Edit.vue'),
        name: 'DAppsTermsNew',
        meta: { title: 'DApps 新增协议' },
        hidden: true
      },
      {
        path: 'terms-edit',
        component: () => import('@/views/dapps/terms/Edit.vue'),
        name: 'DAppsTermsEdit',
        meta: { title: 'DApps 编辑协议' },
        props: (route) => ({ id: parseInt(route.query.id) }),
        hidden: true
      }
    ]
  },

  {
    path: '/operating',
    component: Layout,
    redirect: '/operating/news',
    name: 'Operating',
    meta: {
      title: '运营管理',
      icon: 'el-icon-operation'
    },
    children: [
      // 内容管理
      {
        path: 'news',
        component: () => import('@/views/operating/news/index'),
        name: 'News',
        meta: { title: '内容发布' }
      },
      {
        path: 'news-edit',
        component: () => import('@/views/operating/news/NewsMediumEditor'),
        hidden: true,
        props: (route) => ({ id: parseInt(route.query.id), type: route.query.type }),
        meta: { title: '新闻编辑' }
      },
      {
        path: 'wallet-faq-edit',
        component: () => import('@/views/operating/news/wallet-faq/Edit'),
        hidden: true,
        props: (route) => ({ id: parseInt(route.query.id) }),
        meta: { title: 'FAQ 编辑' }
      },
      {
        path: 'wallet-faq-add',
        component: () => import('@/views/operating/news/wallet-faq/Edit'),
        hidden: true,
        meta: { title: '新增 FAQ' }
      },
      {
        path: 'news-create',
        component: () => import('@/views/operating/news/NewsMediumEditor'),
        hidden: true,
        props: (route) => ({ type: route.query.type }),
        meta: { title: '发布新闻' }
      },
      {
        path: 'images',
        component: () => import('@/views/operating/images/index'), // Parent router-view
        name: 'Images',
        meta: { title: '图片库' }
      },
      {
        path: 'templates',
        component: () => import('@/views/operating/templates/index'), // Parent router-view
        name: 'Templates',
        meta: { title: '运营模板' }
      },
      {
        path: 'add-tmpl',
        component: () => import('@/views/operating/templates/add'),
        hidden: true
      },
      {
        path: 'edit-tmpl',
        component: () => import('@/views/operating/templates/add'),
        hidden: true,
        props: (route) => ({ id: parseInt(route.query.id) })
      },
      {
        path: 'preview',
        component: () => import('@/views/operating/templates/preview'),
        hidden: true,
        props: (route) => ({ id: parseInt(route.query.id) })
      },
      {
        path: 'videos',
        component: () => import('@/views/operating/videos/index'),
        name: 'Videos',
        meta: { title: '视频管理' }
      },
      {
        path: 'partners',
        component: () => import('@/views/operating/partners/index'),
        name: 'partners',
        meta: { title: '合作伙伴' }
      },
      {
        path: 'feedback',
        component: () => import('@/views/operating/feedback/index'),
        name: 'Feedback',
        meta: { title: '建议反馈' }
      },
      {
        path: 'conversion',
        component: () => import('@/views/operating/conversion/index'),
        name: 'Conversion',
        meta: { title: '中英对照' }
      }
    ]
  },

  {
    path: '/system',
    component: Layout,
    redirect: '/system/index',
    meta: {
      title: '系统管理',
      icon: 'el-icon-help',
      perms: ['GET_USER_INFO', 'GET_ROLE_INFO', 'GET_PERM_INFO']
    },
    children: [{
      path: 'user-manage',
      name: '用户管理',
      component: () => import('@/views/system/UserManage/index'),
      meta: { title: '用户管理' }
    }, {
      path: 'variables',
      name: '常量管理',
      component: () => import('@/views/system/Variables'),
      meta: { title: '常量管理' }
    }, {
      path: 'ip-access',
      name: 'IP 访问列表',
      component: () => import('@/views/system/IPAccess'),
      meta: { title: 'IP 访问列表' }
    }, {
      path: 'ip-white-list',
      name: 'IP 白名单',
      component: () => import('@/views/system/WhiteList'),
      meta: { title: 'IP 白名单' }
    }]
  },

  {
    path: '/telegrambot',
    component: Layout,
    redirect: '/telegrambot/index',
    meta: {
      title: '机器人管理',
      icon: 'el-icon-promotion',
      perms: ['MANAGE_TELE_BOT']
    },
    children: [{
      path: 'texttemplates',
      name: '文字模板',
      component: () => import('@/views/telegrambot/texttemplates/index'),
      meta: { title: '文字模板' }
    }, {
      path: 'stakingreport',
      name: '数据周报',
      component: () => import('@/views/telegrambot/stakingreport/index'),
      meta: { title: '数据周报' }
    }, {
      path: 'timedpush',
      name: '定时推送',
      component: () => import('@/views/telegrambot/timedpush/index'),
      meta: { title: '定时推送' }
    }, {
      path: 'pushnow',
      name: '发送消息',
      component: () => import('@/views/telegrambot/pushnow/index'),
      meta: { title: '发送消息' }
    }, {
      path: 'keyboardtemplates',
      name: '底部按钮模板',
      component: () => import('@/views/telegrambot/keyboardtemplates/index'),
      meta: { title: '底部按钮模板' }
    }, {
      path: 'joingroups',
      name: '群列表',
      component: () => import('@/views/telegrambot/joingroups/index'),
      meta: { title: '群列表' }
    }, {
      path: 'newusers',
      name: '新用户列表',
      component: () => import('@/views/telegrambot/newusers/index'),
      meta: { title: '新用户列表' }
    }, {
      path: 'forbiddenwords',
      name: '敏感词',
      component: () => import('@/views/telegrambot/forbiddenwords/index'),
      meta: { title: '敏感词' }
    }, {
      path: 'botcommands',
      name: '自定义命令',
      component: () => import('@/views/telegrambot/botcommands/index'),
      meta: { title: '自定义命令' }
    }, {
      path: 'groupmessagerecord',
      name: '群消息记录',
      component: () => import('@/views/telegrambot/groupmessagerecords/index'),
      meta: { title: '群消息记录' }
    }, {
      path: 'privatemessagerecords',
      name: '私聊消息记录',
      component: () => import('@/views/telegrambot/privatemessagerecords/index'),
      meta: { title: '私聊消息记录' }
    }, {
      path: 'broadcast',
      name: '广播设置',
      component: () => import('@/views/telegrambot/broadcast/index'),
      meta: { title: '广播设置' }
    }]
  },

  {
    path: '/baas',
    component: Layout,
    redirect: '/baas/index',
    meta: {
      title: 'BaaS 平台',
      icon: 'el-icon-management'
    },
    children: [{
      path: 'consult',
      name: '咨询与建议',
      component: () => import('@/views/baas/consult/index'),
      meta: { title: '咨询与建议' }
    }, {
      path: 'other',
      name: '其他',
      component: () => import('@/views/baas/other/index'),
      meta: { title: '开发中...' }
    }]
  },

  {
    path: '/vac-wiki',
    component: Layout,
    children: [
      {
        path: 'https://cpchain.atlassian.net/wiki/spaces/VAC/overview',
        meta: { title: 'VAC Wiki', icon: 'link' }
      }
    ]
  },

  {
    path: '/vac-jira',
    component: Layout,
    children: [
      {
        path: 'https://cpchain.atlassian.net/secure/RapidBoard.jspa?projectKey=VAC&rapidView=23',
        meta: { title: 'VAC Jira', icon: 'link' }
      }
    ]
  }
]

const routerOptions = {
  history: createWebHashHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
}

const router = createRouter(routerOptions)

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter(routerOptions)
  router.matcher = newRouter.matcher // reset router
}

export default router

import { createApp, Component } from 'vue'
import App from './App.vue'

// module
import store from './store'
import router from './router'

// UI
import ElementPlus from 'element-plus'

// request
import request from '@/utils/request'
import requestofbot from '@/utils/requestofbot'

// css
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'element-plus/dist/index.css'
import '@/styles/index.scss' // global css

// icons
import '@/icons' // icon
import SvgIcon from '@/components/SvgIcon/index.vue'// svg component
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import solid from '@fortawesome/fontawesome-free-solid'
import regular from '@fortawesome/fontawesome-free-regular'
import brands from '@fortawesome/fontawesome-free-brands'
import * as ElIcons from '@element-plus/icons-vue'

import '@/permission' // permission control

fontawesome.library.add(solid)
fontawesome.library.add(regular)
fontawesome.library.add(brands)

const vm = createApp(App)

vm.use(store)
vm.use(router)
vm.use(ElementPlus)

vm.config.globalProperties.$http = request
vm.config.globalProperties.$httpbot = requestofbot
vm.config.globalProperties.$host = process.env.VUE_APP_BASE_API

vm.component('FontAwesomeIcon', FontAwesomeIcon)
vm.component('SvgIcon', SvgIcon)

Object.values(ElIcons).forEach((comp: Component) => {
  vm.component(comp.name, comp)
})

vm.mount('#app')

<template>
  <div>
    <el-icon v-if="icon.includes('el-icon')">
      <component :is="handleIcon(icon)" />
    </el-icon>
    <svg-icon
      v-else-if="icon"
      :icon-class="icon"
    />
    <span>{{ title }}</span>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleIcon (icon) {
      return icon.replace('el-icon-', '').replace('el-icon-s-', '')
    }
  }
  // render () {
  //   const { icon, title } = this
  //   const vnodes = []

  //   if (icon) {
  //     if (icon.includes('el-icon')) {
  //       const iconName = 'edit' // icon.replace('el-icon-', '').replace('el-icon-s-', '')
  //       vnodes.push(<el-icon><component :is={iconName} /></el-icon>)
  //     } else {
  //       vnodes.push(<svg-icon icon-class={icon}/>)
  //     }
  //   }

  //   if (title) {
  //     vnodes.push(<span slot='title'>{(title)}</span>)
  //   }
  //   return vnodes
  // }
})
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
</style>
